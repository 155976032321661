import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'connectors/spProvider';
import { baseQueryWithRefresh } from '../authOAuth2/baseQueryWithRefresh';
import {
  File,
  PostFileProps,
  Storage,
  PatchFileProps,
  PostFileResult,
  UserSettings,
  PostStorage,
  CreateWorkflowProps,
  GetBalanceResult,
  CreateWorflowResult,
  ReplenishWorkflowDepositProps,
  ReplenishWorkflowDepositResult,
  CancelWorkflowResult,
  GetFilesProps,
  PatchOrderRequest,
  PatchOrderResponse,
  GetOrderResponse,
  RequestTokensResult,
} from './types';
import { setBalance, setLoadingBalance } from '.';
import { getInitialBalance } from './helpers';

export const api = createApi({
  reducerPath: 'filesApi',
  baseQuery: baseQueryWithRefresh(baseQuery),
  tagTypes: ['Files'],
  endpoints: (builder) => ({
    getUserBalance: builder.query<GetBalanceResult, null>({
      query() {
        return {
          url: 'user-balances/me',
        };
      },
    }),
    updateUserBalance: builder.query<GetBalanceResult, null>({
      query() {
        return {
          url: 'user-balances/me',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoadingBalance(true));
          const { data } = await queryFulfilled;
          dispatch(setBalance({ tee: data?.amount }));
        } catch (e) {
          setBalance(getInitialBalance());
        } finally {
          dispatch(setLoadingBalance(false));
        }
      },
    }),
    getFile: builder.query<File, string>({
      query(file) {
        return {
          url: `files/${file}`,
        };
      },
    }),
    getFiles: builder.query<File[], GetFilesProps | null>({
      query(body) {
        return {
          url: 'files/list',
          method: 'POST',
          body: body || {},
        };
      },
    }),
    postFile: builder.query<PostFileResult, PostFileProps>({
      query(body) {
        return {
          url: 'files',
          method: 'POST',
          body,
        };
      },
    }),
    deleteFile: builder.query<File, string>({
      query(id) {
        return {
          url: `files/${id}`,
          method: 'DELETE',
        };
      },
    }),
    patchFile: builder.query<File, PatchFileProps>({
      query({ body, id }) {
        return {
          url: `files/${id}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    getStorages: builder.query<Storage[], null>({
      query() {
        return {
          url: 'storages',
        };
      },
    }),
    getStorage: builder.query<Storage, string>({
      query(storage) {
        return {
          url: `storages/${storage}`,
        };
      },
    }),
    createCentralizedStorage: builder.query<Storage, null>({
      query() {
        return {
          url: 'storages/centralized',
          method: 'POST',
        };
      },
    }),
    patchOrder: builder.query<PatchOrderResponse, PatchOrderRequest>({
      query({ body, id }) {
        return {
          url: `user-workflows/${id}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    getOrder: builder.query<GetOrderResponse, string>({
      query(id) {
        return {
          url: `user-workflows/${id}`,
        };
      },
    }),
    getOrders: builder.query<GetOrderResponse[], null>({
      query() {
        return {
          url: 'user-workflows',
        };
      },
    }),
    getUserSettings: builder.query<UserSettings, null>({
      query() {
        return {
          url: 'user-settings',
        };
      },
    }),
    updateUserSettings: builder.query<UserSettings, Partial<UserSettings>>({
      query(body) {
        return {
          url: 'user-settings',
          method: 'PATCH',
          body,
        };
      },
    }),
    createUserSettings: builder.query<UserSettings, Partial<UserSettings>>({
      query(body) {
        return {
          url: 'user-settings',
          method: 'POST',
          body,
        };
      },
    }),
    createStorage: builder.query<Storage, PostStorage>({
      query(body) {
        return {
          url: 'storages',
          method: 'POST',
          body,
        };
      },
    }),
    createWorkflow: builder.query<CreateWorflowResult, CreateWorkflowProps>({
      query(body) {
        return {
          url: 'workflows',
          method: 'POST',
          body,
        };
      },
    }),
    replenishWorkflowDeposit: builder.query<ReplenishWorkflowDepositResult, ReplenishWorkflowDepositProps>({
      query(params) {
        const { orderId, body } = params;
        return {
          url: `workflows/${orderId}/replenish`,
          method: 'POST',
          body,
        };
      },
    }),
    cancelWorkflow: builder.query<CancelWorkflowResult, string>({
      query(orderId) {
        return {
          url: `workflows/${orderId}/cancel`,
          method: 'POST',
        };
      },
    }),
    requestTokens: builder.query<RequestTokensResult, null>({
      query() {
        return {
          url: 'faucet/request-tokens',
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetFileQuery,
  useGetFilesQuery,
  useLazyPostFileQuery,
  useLazyPatchFileQuery,
  useLazyDeleteFileQuery,
  useGetStoragesQuery,
  useCreateCentralizedStorageQuery,
  useLazyCreateCentralizedStorageQuery,
  useLazyGetStoragesQuery,
  useLazyCreateUserSettingsQuery,
  useLazyGetUserSettingsQuery,
  useGetUserSettingsQuery,
  useLazyUpdateUserSettingsQuery,
  useGetStorageQuery,
  useLazyGetStorageQuery,
  useLazyCreateStorageQuery,
  useLazyGetFilesQuery,
  useLazyGetFileQuery,
  useCreateWorkflowQuery,
  useLazyCreateWorkflowQuery,
  useGetUserBalanceQuery,
  useLazyGetUserBalanceQuery,
  useUpdateUserBalanceQuery,
  useLazyUpdateUserBalanceQuery,
  useReplenishWorkflowDepositQuery,
  useLazyReplenishWorkflowDepositQuery,
  useCancelWorkflowQuery,
  useLazyCancelWorkflowQuery,
  useGetOrderQuery,
  useLazyGetOrderQuery,
  usePatchOrderQuery,
  useLazyPatchOrderQuery,
  useGetOrdersQuery,
  useLazyGetOrdersQuery,
  useRequestTokensQuery,
  useLazyRequestTokensQuery,
} = api;